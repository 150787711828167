import Vue from 'vue';
import VueApollo from 'vue-apollo';
import {
  createApolloClient,
  restartWebsockets,
} from 'vue-cli-plugin-apollo/graphql-client';
import { split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { createHttpLink } from 'apollo-link-http';
// import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getMainDefinition } from 'apollo-utilities';

// Name of the localStorage item
const AUTH_TOKEN = 'er_token';

function getHeaders() {
  const headers = {};
  const token = localStorage.getItem(AUTH_TOKEN);
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  return headers;
}

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP;
const wssEndpoint = process.env.VUE_APP_GRAPHQL_WSS;

const httpLink = new HttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_GRAPHQL_HTTP,
});

// const wssLink = new WebSocketLink(
//   new SubscriptionClient(wssEndpoint, {
//     options: {
//       reconnect: true,
//       timeout: 30000,
//     },
//     connectionParams: {
//       headers: {
//         Authorization: `Bearer ${localStorage[AUTH_TOKEN]}`,
//       },
//     },
//   })
// );

const wssLink = new WebSocketLink({
  uri: wssEndpoint,
  options: {
    reconnect: true,
    lazy: true,
    timeout: 30000,
    connectionParams: () => {
      return { headers: getHeaders() };
    },
  },
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wssLink,
  httpLink
);

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
  // wsEndpoint: null,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: httpLink,
  link,

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

export function getTempApolloClient(token) {
  const tempHttpLink = createHttpLink({
    uri: process.env.VUE_APP_GRAPHQL_HTTP,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  // Create apollo client
  const { apolloClient } = createApolloClient({
    httpEndpoint,
    link: tempHttpLink,
  });

  return apolloClient;
}

export function getApolloClient() {
  return _apolloClient;
}

let _apolloClient;

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  });
  _apolloClient = apolloClient;
  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {},
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message
      );
    },
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function reloadApollo() {
  await createProvider();
  if (_apolloClient.wsClient) restartWebsockets(_apolloClient.wsClient);
  try {
    await _apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Install the vue plugin
Vue.use(VueApollo);
