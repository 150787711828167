<template>
  <ion-page>
    <app-main-header title="Effect Radio"> </app-main-header>
    <ion-content>
      <ion-list>
        <app-header-ads />
        <div class="ion-padding home__top-block cs-textstyle-paragraph">
          The Effect is a Christian radio ministry that plays the best in Modern
          Christian Alternative Music that included Hip Hop, Rock and Reggae.
          Including all the great Artists you know: twenty-one pilots, Skillet,
          Switchfoot, TobyMac, and rappers NF, KB, Andy Mineo, Derek Minor,
          Flame, Tedashii and underground Artists you will LOVE
        </div>
        <div class="ion-padding">
          <div class="cs-textstyle-paragraph-bold">About</div>
          <p class="cs-textstyle-paragraph">
            The Effect Radio Network (Modern Christian Music) is broadcast on
            88.9FM in Twin Falls, Idaho and in over 50 other US cities.
          </p>
          <p class="cs-textstyle-paragraph-bold">
            Our goal is to share the gospel with the lost, and to provide the
            Christian community with best modern Christian music available
            today.
          </p>
          <p class="cs-textstyle-paragraph">
            1 Thessalonians 2:13 - "For this reason we also thank God without
            ceasing, because when you received the word of God which you heard
            from us, you welcomed it not as the word of men, but as it is in
            truth, the word of God, which also effectively works in you who
            believe."
          </p>
          <p class="cs-textstyle-paragraph">
            The Effect (KEFX, Twin Falls) began broadcasting in 1996 on 88.9 FM,
            from the church building of Calvary Chapel of Twin Falls, which was
            then located in downtown Twin Falls, Idaho. Out of our church, which
            KAWZ 89.9 FM, began in the late 1980s. In 1993, KAWZ became the
            satellite uplink station for CSN
          </p>
        </div>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import AppMainHeader from '@/components/general/MainHeader.vue';
import AppHeaderAds from '@/components/home/HeaderAds.vue';

import ListHeaderAds from '@/graphql/home/ListHeaderAds.gql';

import { mapGetters } from 'vuex';

export default {
  components: {
    AppMainHeader,
    AppHeaderAds,
  },
  apollo: {
    header_ads: {
      query: ListHeaderAds,
    },
  },
  methods: {},
  computed: {
    ...mapGetters('auth', {
      userId: 'userId',
    }),
  },
};
</script>

<style scoped>
.home__top-block {
  padding-top: 16px;
  background-color: var(--er-gray-primary);
}
</style>
