<template>
  <ion-page>
    <app-secondary-header title="Support" />
    <ion-content>
      <ion-list class="ion-padding">
        <div class="er-support">
          <div class="cs-textstyle-section-heading">Effect Radio Staff</div>
          <cs-card class="er-card">
            <div slot="body">
              <div
                v-for="person in people"
                :key="person.designation"
                class="er-items"
              >
                <div class="cs-textstyle-paragraph-bold er-designation">
                  {{ person.designation }}
                </div>
                <div class="cs-textstyle-paragraph">{{ person.name }}</div>
              </div>
            </div>
          </cs-card>

          <div class="cs-textstyle-section-heading er-contact-us">
            Contact Us
          </div>
          <cs-card class="er-card">
            <div slot="body">
              <div class="er-contact-items">
                <div class="er-contact-items_left">
                  <div><i class="cs-icons-phone-filled icons-size"></i></div>
                  <div class="er-contact-items_left--content">
                    <div class="cs-textstyle-paragraph er-contact-item">
                      Phone
                    </div>
                    <div class="cs-textstyle-paragraph">
                      Call us at
                      <a :href="`tel:${phone}`" class="er-contact-medium">{{
                        phone
                      }}</a>
                    </div>
                  </div>
                </div>
                <div class="er-contact-items_right">
                  <i class="cs-icons-copy" @click="copyToClipboard(phone)"></i>
                </div>
              </div>
              <div class="er-contact-items">
                <div class="er-contact-items_left">
                  <div><i class="cs-icons-email-filled icons-size"></i></div>
                  <div class="er-contact-items_left--content">
                    <div class="cs-textstyle-paragraph er-contact-item">
                      Email
                    </div>
                    <div class="cs-textstyle-paragraph">
                      Mail us at
                      <a :href="`mailto:${email}`" class="er-contact-medium">{{
                        email
                      }}</a>
                    </div>
                  </div>
                </div>
                <div class="er-contact-items_right">
                  <i class="cs-icons-copy" @click="copyToClipboard(email)"></i>
                </div>
              </div>
            </div>
          </cs-card>
        </div>
        <div>
          <app-er-social-buttons />
        </div>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import AppSecondaryHeader from '@/components/general/SecondaryHeader.vue';
import AppErSocialButtons from '@/components/general/ErSocialButtons.vue';
import { copyToClipboard } from '@/services/utils';

export default {
  components: {
    AppSecondaryHeader,
    AppErSocialButtons,
  },
  data() {
    return {
      copyToClipboard,
      info: '',
      email: 'info@effectradio.com',
      phone: '+444235667817',
      people: [
        {
          designation: 'Network Manager',
          name: 'AJ Kestler',
        },
        {
          designation: 'VP/General Manager',
          name: 'Shannon Scholten',
        },
        {
          designation: 'Programming Director',
          name: 'Mike Stevens',
        },
      ],
    };
  },
};
</script>

<style scoped>
.er-card {
  margin: 10px 0px !important;
}
.er-items:not(:last-child) {
  margin-bottom: 16px;
}
.er-contact-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.er-contact-items:first-child {
  border-bottom: 1px solid var(--cs-gray-01);
  padding-bottom: 5px;
}
.er-contact-items:last-child {
  padding-top: 5px;
}
.er-contact-items_left {
  display: flex;
  flex-direction: row;
}
.er-contact-items_left--content {
  display: flex;
  flex-direction: column;
}
.icons-size {
  font-size: 30px;
  margin-right: 10px;
  color: var(--cs-gray-03);
}
.er-contact-items_right {
  display: flex;
  align-self: flex-end;
  color: var(--cs-gray-04);
  font-size: 20px;
}
.er-contact-item {
  color: var(--cs-gray-07);
}
.er-contact-medium {
  color: #063973;
  text-decoration: none;
}
.dark .er-contact-medium {
  color: var(--cs-gray-05);
  text-decoration: none;
}
.er-contact-us {
  margin-top: 24px;
}
.er-designation {
  color: var(--cs-gray-06);
}
</style>
