import { getApolloClient } from '@/vue-apollo';
import * as simpleStorage from '@/services/simple-storage';
import $dialog from '@/services/dialog';
import $toast from '@/services/toast';
import $inappbrowser from '@/services/inappbrowser';

import GetNotificationToken from '@/graphql/notifications/GetNotificationToken.gql';
import AddNotificationToken from '@/graphql/notifications/AddNotificationToken.gql';
import RemoveNotificationToken from '@/graphql/notifications/RemoveNotificationToken.gql';

import GetLastNotificationTimestamp from '@/graphql/notifications/GetLastNotificationTimestamp.gql';

export default {
  namespaced: true,
  state: {
    _initialized: false,
    supportsPushNotifications: false,
    hasPushPermissions: null,
    pushRegistered: null,
    loadingPushRegistration: false,
    onNotificationListener: null,
    recentNotificationTimestamp: null,
    lastViewedNotificationsTimestamp: null,
  },
  getters: {
    newNotifications: (state) => {
      const recentTime = new Date(state.recentNotificationTimestamp).getTime();
      const lastViewedTime = new Date(
        state.lastViewedNotificationsTimestamp
      ).getTime();
      return recentTime > lastViewedTime;
    },
    showPushSettings: (state) => {
      if (!state.supportsPushNotifications) return false;
      if (state.hasPushPermissions === null || state.pushRegistered === null)
        return false;
      return !state.hasPushPermissions || !state.pushRegistered;
    },
  },
  mutations: {
    set_initialized(state, val) {
      state._initialized = val;
    },
    setSupportsPushNotifications(state, val) {
      state.supportsPushNotifications = val;
    },
    setHasPushPermissions(state, hasPushPermissions) {
      state.hasPushPermissions = hasPushPermissions;
    },
    setPushRegistered(state, pushRegistered) {
      state.pushRegistered = pushRegistered;
    },
    setLoadingPushRegistration(state, loadingPushRegistration) {
      state.loadingPushRegistration = loadingPushRegistration;
    },
    setNotificationListener(state, listener) {
      state.notificationListener = listener;
    },
    setRecentNotificationTimestamp(state, date) {
      state.recentNotificationTimestamp = date;
    },
    setLastViewedNotificationsTimestamp(state, date) {
      state.lastViewedNotificationsTimestamp = date;
    },
    resetOnLogout(state) {
      state.recentNotificationTimestamp = null;
      state.lastViewedNotificationsTimestamp = null;
    },
  },
  actions: {
    async init({ state, commit, dispatch }) {
      // Important note: this is called after the user is loaded
      if (!window.FCM || state._initialized) return;
      commit('set_initialized', true);
      commit('setSupportsPushNotifications', true);
      dispatch('loadPushRegistration');
      const { FCM } = window;
      await FCM.clearAllNotifications();

      // Check if the device has push notifications permissions enabled disabled
      // Check permissions on device resume
      dispatch('checkPushPermissions');
      document.addEventListener('resume', () =>
        dispatch('checkPushPermissions')
      );

      const pushPayload = await FCM.getInitialPushPayload();
      if (pushPayload) dispatch('handleNotification', pushPayload);

      const notificationListener = FCM.onNotification((payload) => {
        dispatch('handleNotification', payload);
      });
      commit('setNotificationListener', notificationListener);
      // state.onNotificationListener.dispose() // To remove listener
    },
    async checkPushPermissions({ commit }) {
      if (!window.FCM) return;
      const { FCM } = window;
      if (window.cordova && window.cordova.platformId === 'ios') {
        const hasPushPermissions = await FCM.hasPermission();
        commit('setHasPushPermissions', !!hasPushPermissions);
      } else {
        commit('setHasPushPermissions', true);
      }
    },
    async loadPushRegistration({ commit, rootGetters }) {
      if (!window.FCM) return;
      const { FCM } = window;

      const fcmToken = await FCM.getToken();
      commit('setLoadingPushRegistration', true);

      const {
        data: {
          fcm_token: [hasToken],
        },
      } = await getApolloClient()
        .query({
          query: GetNotificationToken,
          variables: {
            userId: rootGetters['auth/userId'],
            token: fcmToken,
          },
        })
        .catch((error) => {
          console.log('error', error);
        });
      commit('setLoadingPushRegistration', false);
      commit('setPushRegistered', !!hasToken);
    },
    async enableNotifications({ commit, rootGetters }) {
      if (!window.FCM) return;
      const { FCM } = window;

      if (window.cordova && window.cordova.platformId === 'ios') {
        const permissionGranted = await FCM.requestPushPermission({
          ios9Support: {
            timeout: 15, // How long it will wait for a decision from the user before returning `false`
            interval: 0.3, // How long between each permission verification
          },
        });
        commit('setHasPushPermissions', !!permissionGranted);
        if (!permissionGranted)
          return $dialog.alert({
            title: 'Push notifications',
            message:
              'You have disabled push notifications for this app on your device.  Please go to your device settings and enable push notifications to proceed.',
          });
      } else {
        // On Android just request, but don't await - doesn't return on subsequent requests on my Samsung
        // But you can still retrieve the FCM token
        FCM.requestPushPermission({});
      }

      const fcmToken = await FCM.getToken();

      commit('setLoadingPushRegistration', true);
      const response = await getApolloClient()
        .mutate({
          mutation: AddNotificationToken,
          variables: {
            userId: rootGetters['auth/userId'],
            token: fcmToken,
          },
        })
        .catch((error) => {
          $toast.show(
            `Error: unable to enable push notifications: ${error.message}`
          );
          commit('setPushRegistered', false);
          // TODO: handle failed to register;
        });
      if (response) {
        commit('setPushRegistered', true);
        $toast.show('Push notifications are enabled');
      }
      commit('setLoadingPushRegistration', false);
    },
    async disableNotifications({ commit, rootGetters }) {
      if (!window.FCM) return;
      const { FCM } = window;

      commit('setPushRegistered', false);
      const fcmToken = await FCM.getToken();

      commit('setLoadingPushRegistration', true);
      const response = await getApolloClient()
        .mutate({
          mutation: RemoveNotificationToken,
          variables: {
            userId: rootGetters['auth/userId'],
            token: fcmToken,
          },
        })
        .catch((error) => {
          $toast.show(
            `Error: unable to disable push notifications: ${error.message}`
          );
          commit('setPushRegistered', true);
          // TODO: handle failed to remove;
        });
      if (response) {
        commit('setPushRegistered', false);
        $toast.show('Push notifications are disabled');
      }
      commit('setLoadingPushRegistration', false);
    },
    async handleNotification({ dispatch }, payload) {
      console.log('handle n', payload);
      if (!payload) return;

      if (window.FCM) window.FCM.clearAllNotifications();

      // TODO: may need to be more selective about when to clear this depending on timestamp of notification
      dispatch('updateLastViewedNotificationsPage');
      const { body: message, title, image, link } = payload;
      // TODO: put the start time here so we can do a quick comparison to the current time
      //  and show a more relevant notification if they don't open immediate
      if (link && link.length) {
        const openLink = await $dialog.confirm({
          image,
          title,
          message,
          okayLabel: 'View',
          cancelLabel: 'Close',
        });
        if (openLink) {
          $inappbrowser.openExternal(link);
        }
      } else {
        $dialog.alert({
          image,
          title,
          message,
          okayLabel: 'Close',
        });
      }
    },
    async checkNotifications({ commit, dispatch }) {
      dispatch('getRecentNotificationTimestamp');
      const lastViewedNotificationsTimestamp = await simpleStorage.getItem(
        'notificationsLastChecked'
      );
      if (lastViewedNotificationsTimestamp) {
        commit(
          'setLastViewedNotificationsTimestamp',
          Number(lastViewedNotificationsTimestamp)
        );
      }
    },
    async getRecentNotificationTimestamp({ commit, rootGetters }) {
      const id = rootGetters['auth/userId'];
      const { data } = await getApolloClient().query({
        query: GetLastNotificationTimestamp,
        fetchPolicy: 'no-cache',
        variables: {
          userId: id,
        },
      });
      if (data.notification.length) {
        commit(
          'setRecentNotificationTimestamp',
          +new Date(data.notification[0].created_at)
        );
        return +new Date(data.notification[0].created_at);
      }
    },
    async updateLastViewedNotificationsPage({ commit }) {
      const lastViewed = Date.now();
      simpleStorage.setItem('notificationsLastChecked', lastViewed);
      commit('setLastViewedNotificationsTimestamp', lastViewed);
    },
    async logout({ commit, dispatch }) {
      commit('resetOnLogout');
      if (!window.FCM) return;
      await dispatch('disableNotifications');
      await window.FCM.deleteInstanceId();
      // TODO: delete notification FCM token
    },
  },
};
