import Vue from 'vue';
import { IonicVueRouter } from '@ionic/vue';
Vue.use(IonicVueRouter);

import Tabs from '@/Tabs.vue';
import Live from '@/pages/live/Live.vue';
import Home from '@/pages/home/Home.vue';
import Donate from '@/pages/donate/Donate.vue';
import Menu from '@/pages/menu/Menu.vue';
import Notifications from '@/pages/menu/Notifications.vue';
import Support from '@/pages/menu/Support.vue';
import Login from '@/pages/onboarding/Login.vue';
import Onboarding from '@/pages/onboarding/Onboarding.vue';
import { store as $store } from '@/store';
import $theme from '@/services/theme';

// meta features:
// preventBack - prevents the user going back using the Android back button
// notabs - hides the bottom tab bar for that route/view. (see Tabs.vue)
//   This is mainly for transaction views where we want the page to navigate forward at the user progresses
//   but don't want to allow them to exit the transaction flow except by the approved X button

// Pages to prompt closing the app on android back button;
// const rootPages = ['home', 'live', 'channels', 'stations', 'menu'];

const routes = [
  {
    path: '/tabs',
    name: 'tabs',
    component: Tabs,
    children: [
      {
        path: 'live',
        name: 'live',
        components: {
          live: Live,
        },
        meta: { preventBack: true, promptClose: true },
      },
      {
        path: 'home',
        name: 'home',
        components: {
          home: Home,
        },
        meta: { preventBack: true, promptClose: true },
      },
      {
        path: 'donate',
        name: 'donate',
        components: {
          donate: Donate,
        },
        meta: { preventBack: true, promptClose: true },
      },
      {
        path: 'menu',
        name: 'menu',
        components: {
          menu: Menu,
        },
        meta: { preventBack: true, promptClose: true },
      },
      {
        path: 'menu/notifications',
        name: 'notifications',
        components: {
          menu: Notifications,
        },
      },
      {
        path: 'menu/support',
        name: 'support',
        components: {
          menu: Support,
        },
      },
      {
        path: 'notabs/tutorial',
        name: 'onboarding',
        components: {
          notabs: Onboarding,
        },
        meta: { preventBack: true, notabs: true, public: true },
      },
      {
        path: 'notabs/login',
        name: 'login',
        components: {
          notabs: Login,
        },
        meta: { preventBack: true, notabs: true, public: true },
      },
    ],
  },
  {
    path: '/', // Sets the default path for when you load the app
    redirect: '/tabs/live',
  },
];

const router = new IonicVueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (['login', 'onboarding'].indexOf(to.name) > -1) {
    $theme.applyLogin();
  } else {
    $theme.applyDefault();
  }
  if (to.meta.public) return next();
  const loggedInState = await $store.dispatch('auth/fetchUserLoginState');
  if (loggedInState === 'loggedin') return next();
  if (loggedInState === 'onboarded')
    return next({ path: '/tabs/notabs/login' });
  if (!loggedInState) return next({ path: '/tabs/notabs/tutorial' });
  return next();
});

export default router;
