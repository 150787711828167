<template>
  <div class="er-social-buttons">
    <cs-social-icon-button
      class="er-social-button"
      network="network"
      @click="openGoogle"
    />
    <cs-social-icon-button
      class="er-social-button"
      network="facebook"
      @click="openFacebook"
    />
    <cs-social-icon-button
      class="er-social-button"
      network="youtube"
      @click="openYoutube"
    />
    <cs-social-icon-button
      class="er-social-button"
      network="instagram"
      @click="openInstagram"
      icon="cs-icons-instagram-filled"
    />
    <cs-social-icon-button
      class="er-social-button"
      network="twitter"
      @click="openTwitter"
      icon="cs-icons-twitter-filled"
    />
  </div>
</template>
<script>
import $iab from '@/services/inappbrowser';

const google_url = 'https://effectradio.com/';
const facebook_url = 'https://www.facebook.com/EffectRadioNetwork/';
const youtube_url = 'https://www.youtube.com/@effectradio5028';
const instagram_url = 'https://www.instagram.com/theeffectradio/';
const twitter_url = 'https://twitter.com/effectradio';

export default {
  methods: {
    openGoogle() {
      $iab.openExternal(google_url);
    },
    openFacebook() {
      $iab.openExternal(facebook_url);
    },
    openYoutube() {
      $iab.openExternal(youtube_url);
    },
    openInstagram() {
      $iab.openExternal(instagram_url);
    },
    openTwitter() {
      $iab.openExternal(twitter_url);
    },
  },
};
</script>
<style scoped>
.er-social-buttons {
  display: flex;
  justify-content: center;
  gap: 32px;
  margin-top: 40px;
}
.er-social-buttons .er-social-button {
  background-color: var(--cs-primary-base);
}
</style>
