<template>
  <ion-page>
    <app-secondary-header title="Notifications" />
    <ion-content>
      <ion-list
        class="ion-padding"
        v-if="$apollo.queries.notifications.loading"
      >
        <skeleton-notification-list-item v-for="i in 10" :key="i" />
      </ion-list>
      <ion-list class="ion-padding" v-else>
        <app-push-notifications-toggle />
        <template v-if="notifications && notifications.length">
          <app-notifications-list :notifications="notifications" />
        </template>
        <cs-empty-state
          v-else-if="notifications && notifications.length === 0"
          title="No notifications yet"
        >
        </cs-empty-state>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import AppSecondaryHeader from '@/components/general/SecondaryHeader.vue';
import AppNotificationsList from '@/components/notifications/NotificationsList.vue';
import AppPushNotificationsToggle from '@/components/notifications/PushNotificationsToggle.vue';
import SkeletonNotificationListItem from '@/components/skeletons/SkeletonNotificationListItem.vue';

import ListNotifications from '@/graphql/notifications/ListNotifications.gql';

export default {
  components: {
    AppSecondaryHeader,
    AppNotificationsList,
    AppPushNotificationsToggle,
    SkeletonNotificationListItem,
  },
  apollo: {
    notifications: {
      query: ListNotifications,
      fetchPolicy: 'cache-and-network',
    },
  },
  methods: {
    refreshNotifications() {
      this.$apollo.queries.notifications.refetch();
    },
  },
  created() {
    this.refreshNotifications();
    document.addEventListener('resume', this.refreshNotifications);
  },
};
</script>

<style scoped>
.skeleton__header {
  display: flex;
  justify-content: center;
}
</style>
