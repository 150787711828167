<template>
  <ion-page>
    <app-main-header title="Donate" />
    <ion-content>
      <ion-list class="ion-padding">
        <div class="ion-padding er-donate__top-block">
          <div class="cs-textstyle-section-heading er-donate__top-block-title">
            {{ blockTitle }}
          </div>
          <div class="cs-textstyle-paragraph er-donate__top-block-content">
            {{ blockContent }}
          </div>
        </div>
        <cs-button-group
          size="small"
          :values="selectedOptions"
          :buttons="donateOptions"
          wrap
          @input="selectedOptions = $event"
        />
        <component :is="selectedOption.component" />
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import AppMainHeader from '@/components/general/MainHeader.vue';
import AppOneTimeDetails from '@/components/donate/OneTimeDetails.vue';
import AppSubscribeDetails from '@/components/donate/SubscribeDetails.vue';
import AppPhoneMailDetails from '@/components/donate/PhoneMailDetails.vue';

const blockTitle =
  'Effect Radio is listener-supported. Your donations keep us on the air! ';
const blockContent = `Effect Radio is a non-commercial, listener-supported. FM radio network. By supporting the Effect (one time or monthly), you are helping us to maintain and build Effect Radio stations. All amounts shown for "thank-you gifts" on this page are tax-deductible donations.There are no additional tax or shipping charges. 
`;
const contributeTitle = 'Contribute to the community';

const donateOptions = [
  {
    label: 'One Time',
    value: 0,
    component: AppOneTimeDetails,
  },
  {
    label: 'Become a Partner',
    value: 1,
    component: AppSubscribeDetails,
  },
  {
    label: 'Phone/Mail',
    value: 2,
    component: AppPhoneMailDetails,
  },
];

export default {
  components: {
    AppMainHeader,
  },
  computed: {
    selectedOption() {
      return this.selectedOptions[0];
    },
  },
  data() {
    return {
      blockTitle,
      blockContent,
      contributeTitle,
      donateOptions,
      selectedOptions: [donateOptions[0]],
    };
  },
  methods: {},
};
</script>

<style scoped>
.er-donate__top-block {
  background-color: var(--cs-gray-02);
  margin-bottom: 24px;
  border-radius: 5px;
}
.er-donate__top-block-title {
  margin-bottom: 8px;
}
::v-deep .cs-button-group__button {
  text-transform: uppercase;
}
</style>
