<template>
  <app-donate-details>
    <div class="cs-textstyle-item-heading er-donate-details__title">
      Donate by Phone
    </div>
    <div class="cs-textstyle-paragraph er-donate-details__description">
      <p>
        You may call us to make one time donation, or to set up a recurring
        monthly
        <app-phone-link phone="800-357-4226" />
      </p>
    </div>
    <div class="cs-textstyle-item-heading er-donate-details__title">
      Donate by Mail
    </div>
    <div class="cs-textstyle-paragraph er-donate-details__description">
      <p>
        Download and print our donation form. Completed forms can be sent, along
        with payment in the form of check or money order, to:
      </p>
      <p>
        Effect Radio<br />
        PO Box 271<br />
        Twin Falls, ID 83303
      </p>
      <p class="cs-textstyle-paragraph-small">
        You can also support Effect Radio by making a donation for our available
        books, videos, and other resources, on the Effect Radio store.
      </p>
    </div>
  </app-donate-details>
</template>
<script>
import AppDonateDetails from '@/components/donate/Details.vue';
import AppPhoneLink from '@/components/general/PhoneLink.vue';

export default {
  components: {
    AppDonateDetails,
    AppPhoneLink,
  },
};
</script>
