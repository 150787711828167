<template>
  <div class="previously-played-item" @click="onPlay">
    <div class="previously-played-item__content">
      <img :src="picture" alt="" />
      <div class="previously-played-item__content-info">
        <div class="cs-textstyle-paragraph-bold previously-played-item__title">
          {{ title }}
        </div>
        <div
          v-if="item.artist"
          class="cs-textstyle-paragraph-small previously-played-item__artist"
        >
          {{ item.artist }}
        </div>
        <div
          v-if="item.start_time"
          class="cs-textstyle-paragraph-extra-small previously-played-item__time-ago"
        >
          {{ time_ago(item.start_time) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { time_ago } from '@/services/utils';
import $dialog from '@/services/dialog';

export default {
  components: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      time_ago,
    };
  },
  computed: {
    title() {
      return this.item.title || 'Modern Christian Music';
    },
    picture() {
      return this.item.picture
        ? this.item.picture
        : 'https://effectradio.com/img/albums300/default-e.png';
    },
  },
  methods: {
    async onPlay() {
      $dialog.alert({
        title: this.title,
        image: this.picture,
        message: this.item.artist,
      });
      console.log('Play this track');
    },
  },
};
</script>

<style scoped>
img {
  width: 65px;
  height: 65px;
  object-fit: cover;
}
.previously-played-item {
  background-color: var(--er-gray-primary);
  padding: 8px 16px 8px 8px;
  margin-bottom: 16px;
  border-radius: 5px;
}
.previously-played-item__content {
  display: flex;
}
.previously-played-item__content-info {
  padding-left: 16px;
}
.previously-played-item__title {
  color: var(--cs-gray-06);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.previously-played-item__artist {
  color: var(--cs-gray-06);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.previously-played-item__time-ago {
  color: var(--cs-gray-04);
  margin-top: 6px;
}
</style>
