<template>
  <div class="er-audio-player">
    <img :src="picture" />
    <div class="er-audio-player__img-bg"></div>
    <app-play-pause-button />
    <div class="er-audio-player__controls">
      <div>
        <div>
          <div class="cs-textstyle-page-subtitle">
            {{ title }}
          </div>
          <div v-if="artist" class="cs-textstyle-paragraph">
            {{ artist }}
          </div>
        </div>
        <template v-if="radioEntry">
          <div>
            <i
              class="cs-icons-like"
              :class="{ 'cs-icons-like-filled': liked }"
              @click="onReaction('like')"
            ></i>
            <i
              class="cs-icons-like"
              :class="{ 'cs-icons-like-filled': disliked }"
              @click="onReaction('dislike')"
            ></i>
          </div>
        </template>
      </div>
      <template v-if="song">
        <app-progress-bar
          v-if="trackLength"
          class="er-audio-player__progress"
          :progress="progressOfShow"
        />
        <div class="cs-textstyle-tags">{{ showTime }}</div>
      </template>
    </div>
  </div>
</template>
<script>
import AppPlayPauseButton from '@/components/audio/PlayPauseButton.vue';
import AppProgressBar from '@/components/general/ProgressBar.vue';

import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    AppPlayPauseButton,
    AppProgressBar,
  },
  computed: {
    ...mapState('audio', {
      audioPlaying: 'isPlaying',
      audioProgress: 'audioProgress',
      audioLoading: 'audioLoading',
    }),
    ...mapState('nowplaying', {
      radioEntry: 'track',
    }),
    ...mapGetters('nowplaying', {
      liked: 'liked',
      disliked: 'disliked',
      song: 'song',
      title: 'title',
      artist: 'artist',
      picture: 'picture',
      trackLength: 'trackLength',
      timePassedOfTrack: 'timePassedOfTrack',
      progressOfShow: 'progressOfShow',
      showTime: 'showTime',
    }),
  },
  methods: {
    async onReaction(reaction) {
      this.$store.dispatch('nowplaying/react', reaction);
    },
  },
  created() {
    this.$store.dispatch('nowplaying/init');
  },
};
</script>
<style scoped>
.er-audio-player {
  position: relative;
}
.er-audio-player__img-bg {
  position: absolute;
  top: 0;
  height: 361px;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.529) 65.2%,
    rgba(0, 0, 0, 0.851) 100%
  );
}
.er-audio-player img {
  height: 361px;
  object-fit: fill;
  width: 100%;
}
.er-play-pause-button {
  position: absolute;
  top: 37%;
  left: 45%;
}
.er-audio-player__controls {
  position: absolute;
  bottom: 0px;
  width: 100%;
  color: white;
  padding: 16px;
}
.er-audio-player__controls div:first-child {
  display: flex;
  justify-content: space-between;
}
.er-audio-player__controls div:first-child div:first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.er-audio-player__controls div:first-child div:last-child {
  display: flex;
  align-items: flex-start;
}
.er-audio-player__controls div:first-child div:last-child i {
  font-size: 22px;
  padding: 10px;
  margin-left: 10px;
}
.er-audio-player__controls div:first-child div:last-child i:last-child {
  transform: rotate(180deg) scaleX(-1);
}
.er-audio-player__controls .er-progress-bar {
  margin-top: 10px;
  margin-bottom: 6px;
}
</style>
