import { getApolloClient } from '@/vue-apollo';

import InsertTrackReaction from '@/graphql/track/InsertTrackReaction.gql';

let initialized = false;

// Note: we track the song artist, album, reactions, elapsed time, etc here so they can immediately
//   load when the player is loaded.  And if we need to add another mini-player like in CSN the
//   playback information will be in sync

export default {
  namespaced: true,
  state: {
    isLoading: false,
    track: null,
    now: Date.now(),
  },
  getters: {
    reaction(state) {
      console.log('reaction', state.track);
      if (
        !state.track ||
        !state.track.radio_track_reactions ||
        !state.track.radio_track_reactions.length
      )
        return null;
      console.log('reaction', state.track.radio_track_reactions[0]);

      const reaction = state.track.radio_track_reactions[0];
      return reaction;
    },
    liked(_, getters) {
      const reaction = getters.reaction;
      return !!(reaction && reaction.reaction === 'like');
    },
    disliked(_, getters) {
      const reaction = getters.reaction;
      return reaction && reaction.reaction === 'dislike';
    },
    showReactions(state) {
      return !!state.track;
    },
    song(state) {
      if (!state.track) return null;
      return state.track.track;
    },
    title(_, getters) {
      if (!getters.song || !getters.song.title) return 'Modern Christian Music';
      return getters.song.title;
    },
    artist(_, getters) {
      if (!getters.song || !getters.song.artist) return '';
      return getters.song.artist;
    },
    picture(_, getters) {
      if (!getters.song || !getters.song.picture)
        return 'https://effectradio.com/img/albums300/default-e.png';
      return getters.song.picture;
    },
    trackLength(state) {
      if (!state.track) return;
      if (!state.track.start_time || !state.track.end_time) return;
      const startTime = new Date(state.track.start_time);
      const endTime = new Date(state.track.end_time);
      return endTime.valueOf() - startTime.valueOf();
    },
    timePassedOfTrack(state, getters) {
      if (!state.track) return;
      if (!state.track.start_time) return;
      if (!getters.trackLength) return;
      const startTime = new Date(state.track.start_time);
      let timePassed = state.now - startTime.valueOf();
      if (timePassed < 0) timePassed = 0;
      if (timePassed > getters.trackLength) timePassed = getters.trackLength;
      return timePassed;
    },
    progressOfShow(_, getters) {
      if (!getters.trackLength || !getters.timePassedOfTrack) return 0;
      return (getters.timePassedOfTrack / getters.trackLength) * 100;
    },
    showTime(_, getters) {
      if (!getters.trackLength || !getters.timePassedOfTrack) return '';
      const displayLengthMinutes = Math.round(getters.trackLength / 1000 / 60);
      const progressSeconds = Math.round(getters.timePassedOfTrack / 1000);
      const displaySeconds = Math.round(progressSeconds % 60);
      const formattedSeconds =
        displaySeconds < 10 ? `0${displaySeconds}` : displaySeconds;
      const displayMinutes = Math.floor(progressSeconds / 60);
      return `${displayMinutes}:${formattedSeconds} / ${displayLengthMinutes} Min`;
    },
  },
  mutations: {
    setTrack(state, trackData) {
      if (trackData && trackData.track) {
        state.track = trackData;
      } else {
        state.track = null;
      }
    },
    setNow(state, timestamp) {
      state.now = timestamp;
    },
  },
  actions: {
    init({ commit, dispatch }) {
      // Only run this once, initialise the interval checker
      // The interval checker does two things:
      // 1) Updates the time elapsed on the song
      // 2) Runs a check to see if the song has finished.
      //   (if the song has finished and there is no new song, set it to null in order to display default information)
      if (initialized) return;
      initialized = true;
      setInterval(() => {
        commit('setNow', Date.now());
        dispatch('checkTrackNowPlaying');
      }, 1000);
    },
    updateTrack({ commit, dispatch }, radioEntry) {
      console.log('update', radioEntry);
      // Update the track to the next track.  However, there can sometimes be preloaded songs
      //  in the preloaded case, set a timeout until around when the new song should start
      if (radioEntry && radioEntry.track) {
        const startTime = new Date(radioEntry.start_time);
        const timeDifference = startTime.getTime() - Date.now();
        if (timeDifference > 1000) {
          console.log('timeout', timeDifference);
          setTimeout(() => {
            dispatch('updateTrack', radioEntry);
          }, timeDifference - 1000);
        } else {
          console.log('set trakc');
          commit('setTrack', radioEntry);
        }
      } else {
        console.log('notrack');
        commit('setTrack', null);
      }
    },
    async react({ commit, state, getters, rootGetters }, reaction) {
      // If there is no track to react to, return
      const track = state.track;
      if (!track) return;

      const currentReaction = getters.reaction;
      if (!currentReaction) {
        // If there is no current reaction from the user, the current reaction made will be saved
      } else if (currentReaction.reaction === reaction) {
        // If there was a previous reaction and the user previously reacted in this way, treat it as a toggle
        // So "untoggle" the user reaction (if the user previously liked it, pressing like again will remove the like)
        reaction = '';
      }

      track.radio_track_reactions = [{ reaction }];
      commit('setTrack', track);

      const userId = rootGetters['auth/userId'];
      if (!userId) return;

      getApolloClient().mutate({
        mutation: InsertTrackReaction,
        variables: {
          userId,
          reaction,
          radioTrackId: track.id,
        },
      });
    },
    checkTrackNowPlaying({ commit, state }) {
      if (!state.track) return;
      // Check every second if there is a valid track to display
      // If the last track ended more than 3 seconds ago, set the now playing to null in order to show default data
      const trackEnd = new Date(state.track.end_time);
      if (Date.now() - trackEnd.getTime() > 3000) {
        commit('setTrack', null);
      }
    },
  },
};
