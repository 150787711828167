<template>
  <app-modal v-bind="$attrs" @close="onClose">
    <template v-slot:header>
      <template>Edit Profile</template>
      <cs-spinner v-if="loading" class="header-loader" />
    </template>

    <div v-if="user && user.is_guest">
      <app-link-account class="ion-padding-bottom" :userId="user.id" />
    </div>

    <cs-form v-if="user && !user.is_guest" v-model="formValid">
      <cs-file-input
        class="input"
        label="Photo"
        v-model="userData.picture"
        accept-type="image/*"
        :application-id="gxAppId"
      ></cs-file-input>
      <br />
      <cs-input
        class="input"
        label="Name"
        v-model="userData.name"
        required
      ></cs-input>

      <cs-button @click="save()" :disabled="!formValid" block corners="rounded"
        >Save</cs-button
      >
    </cs-form>

    <app-delete-account />
  </app-modal>
</template>
<script>
import AppModal from '@/components/modals/Modal.vue';
import AppDeleteAccount from '@/components/menu/DeleteAccount.vue';
import AppLinkAccount from '@/components/menu/LinkAccount.vue';
import UpdateUser from '@/graphql/user/UpdateUser.gql';
import { mapState, mapGetters } from 'vuex';
import $dialog from '@/services/dialog';

export default {
  components: {
    AppModal,
    AppDeleteAccount,
    AppLinkAccount,
  },
  computed: {
    ...mapState('auth', {
      loginWindowOpen: 'loginWindowOpen',
    }),
    ...mapGetters('auth', {
      user: 'user',
    }),
    userData() {
      return { ...this.user };
    },
  },
  data() {
    return {
      formValid: false,
      loading: false,
      gxAppId: process.env.VUE_APP_GX_APP_ID,
    };
  },
  methods: {
    async save() {
      try {
        this.loading = true;
        await this.$apollo.mutate({
          mutation: UpdateUser,
          variables: {
            id: this.userData.id,
            name: this.userData.name,
            picture: this.userData.picture,
          },
          update: (store, resp) => {
            this.$store.dispatch('auth/updateUser', resp.data.user);
            this.$emit('close');
            this.loading = false;
          },
        });
      } catch (error) {
        this.loading = false;
        $dialog.alert({
          title: error.message,
        });
      }
    },
    async onClose() {
      if (this.userData.name != this.user.name) {
        const discard = await $dialog.confirm({
          title: 'Discard',
          message: 'Discard unsaved changes?',
          okayLabel: 'Discard',
        });
        if (!discard) return;
      }
      this.userData.name = this.user.name;
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.header-loader {
  position: absolute;
  right: 20px;
}
.header-loader {
  top: -68px;
}
</style>
