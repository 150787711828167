<template>
  <div id="app">
    <ion-app>
      <ion-vue-router></ion-vue-router>
    </ion-app>
    <audio id="audio-player"></audio>
  </div>
</template>
<script>
import * as simpleStorage from '@/services/simple-storage';

export default {
  async mounted() {
    this.$store.dispatch('auth/setInitialState');
    const token = await simpleStorage.getItem('er_token');
    if (token) {
      this.$store.dispatch('auth/loginUser', token);
    }
    navigator.splashscreen && navigator.splashscreen.hide();
  },
  // async mounted() {
  // 	await ensureDeviceReady();
  // 	// Init setups mainly loads Branch plugin (when the app is opened by a link)
  // 	$init.setup();
  // 	navigator.splashscreen && navigator.splashscreen.hide();
  // },
};
</script>
<style scoped>
#audio-player {
  position: fixed;
  top: -100px;
  opacity: 0;
  z-index: -1;
}
</style>
