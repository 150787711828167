<template>
  <ion-page>
    <app-main-header title="More" />
    <ion-content class="er-more">
      <ion-list>
        <cs-navigation-page class="er-navigation-page">
          <div class="er-more__profile" slot="profile" @click="openEditUser">
            <cs-profile
              v-if="user"
              :picture="user.picture"
              :name="user.is_guest ? 'Guest User' : user.name || 'No Name'"
              :detail="user.email"
              :company-logo="email_logo"
              avatar-position="left"
              avatar-size="medium"
            />
            <div class="er-more__button-holder">
              <cs-button variant="primary" fill="clear"> EDIT </cs-button>
            </div>
          </div>
          <cs-navigation-page-link
            slot="link"
            title="Notifications"
            icon="cs-icons-notification-filled"
            @click="goto({ name: 'notifications' })"
          />
          <cs-navigation-page-link
            slot="link"
            title="Support"
            icon="cs-icons-help-filled"
            @click="goto({ name: 'support' })"
          />
          <cs-navigation-page-link
            slot="link"
            title="Log Out"
            icon="cs-icons-logout-filled"
            @click="logout"
          />
        </cs-navigation-page>
        <div class="ion-padding">
          <app-dark-mode-toggle />
          <app-er-social-buttons />
        </div>
      </ion-list>
    </ion-content>
    <app-edit-profile-modal
      :show="showEditProfileModal"
      @close="showEditProfileModal = false"
    />
  </ion-page>
</template>

<script>
import AppMainHeader from '@/components/general/MainHeader.vue';
import AppErSocialButtons from '@/components/general/ErSocialButtons.vue';

import AppEditProfileModal from '@/components/modals/EditProfileModal.vue';

import AppDarkModeToggle from '@/components/menu/DarkModeToggle.vue';

import { mapState } from 'vuex';

import $dialog from '@/services/dialog';

const email_logo = require('@/assets/images/cs-email-filled.svg');

export default {
  components: {
    AppMainHeader,
    AppErSocialButtons,
    AppEditProfileModal,
    AppDarkModeToggle,
  },
  computed: {
    ...mapState('auth', {
      user: 'user',
    }),
  },
  data() {
    return {
      showEditProfileModal: false,
      email_logo,
    };
  },
  methods: {
    async logout() {
      const confirmed = await $dialog.confirm({
        title: 'Logout',
        message: 'Are you sure you wish to logout?',
        okayLabel: 'Logout',
      });
      if (!confirmed) return;
      this.$store.dispatch('auth/logout');
    },
    goto(route) {
      this.$router.push(route);
    },
    openEditUser() {
      this.showEditProfileModal = true;
    },
  },
};
</script>

<style scoped>
.er-more .er-more__profile {
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-top: 2px;
  background-color: var(--er-gray-primary);
  position: relative;
  width: 100%;
}
.er-more__button-holder {
  position: absolute;
  right: 0;
  background-color: var(--er-gray-primary);
  top: 50%;
  margin-top: -20px;
}
.er-more .er-navigation-page {
  background-color: var(--er-gray-01);
}
.er-more >>> .cs-navigation-page__line-wrapper {
  display: none;
}
.dark .er-more .er-more__profile .cs-profile {
  --cs-profile-background: none;
}
.er-more__profile ::v-deep img.cs-profile__content-logo {
  border-radius: 0;
}
</style>
