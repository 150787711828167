<template>
  <ion-header class="er-secondary-header">
    <cs-secondary-header
      class="er-secondary-header__header"
      :title="title"
      align-title="center"
      @back="goBack"
    />
  </ion-header>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.er-secondary-header .er-secondary-header__header {
  box-shadow: none;
  --cs-secondary-header-background-color: var(--er-gray-primary);
  --cs-gray-03: var(--cs-gray-08);
}
</style>
