<template>
  <app-modal v-bind="$attrs" @close="onClose">
    <template v-slot:header>
      <template>Request a Song</template>
      <cs-button
        fill="clear"
        corners="rounded"
        @click="send()"
        :disabled="!bodyData.songTitle"
        class="header-button"
      >
        Send
      </cs-button></template
    >

    <cs-form>
      <cs-input
        class="input"
        label="Name"
        v-model="userData.name"
        disabled
      ></cs-input>
      <cs-input
        class="input"
        label="Email"
        v-model="userData.email"
        disabled
      ></cs-input>
      <cs-input
        class="input"
        label="Song Title *"
        placeholder="Name of the song you want"
        v-model="bodyData.songTitle"
      >
        <cs-input-note
          slot="input-notes"
          v-if="!bodyData.songTitle || !bodyData.songTitle.length"
          type="error"
          label="Required"
        />
      </cs-input>
      <cs-input
        class="input"
        label="Song Artist"
        placeholder="The artist who performed it"
        v-model="bodyData.artist"
      ></cs-input>
      <cs-textarea
        class="input"
        label="Message"
        placeholder="Any other message you would like to share"
        v-model="bodyData.message"
      ></cs-textarea>
    </cs-form>
  </app-modal>
</template>
<script>
import AppModal from '@/components/modals/Modal.vue';
import SendEmail from '@/graphql/general/SendEmail.gql';
import { mapState, mapGetters } from 'vuex';
import $dialog from '@/services/dialog';

export default {
  components: {
    AppModal,
  },
  computed: {
    ...mapState('auth', {
      loginWindowOpen: 'loginWindowOpen',
    }),
    ...mapGetters('auth', {
      user: 'user',
    }),
    userData() {
      return { ...this.user };
    },
  },
  data() {
    return {
      bodyData: {
        songTitle: '',
        artist: '',
        message: '',
      },
    };
  },
  methods: {
    async send() {
      this.$emit('close');
      try {
        await this.$apollo.mutate({
          mutation: SendEmail,
          variables: {
            name: this.userData.name,
            email: this.userData.email,
            ...this.bodyData,
          },
          update: () => {
            this.loading = false;
            this.resetChanges();
            $dialog.alert({
              title: 'Thank you for your song request',
            });
          },
        });
      } catch (error) {
        $dialog.alert({
          title: error.message,
        });
      }
    },
    async onClose() {
      if (
        this.bodyData.songTitle != '' ||
        this.bodyData.artist != '' ||
        this.bodyData.message != ''
      ) {
        const discard = await $dialog.confirm({
          title: 'Discard',
          message: 'Discard unsaved changes?',
          okayLabel: 'Discard',
        });
        if (!discard) return;
      }
      this.resetChanges();
      this.$emit('close');
    },
    async resetChanges() {
      this.bodyData = {
        songTitle: '',
        artist: '',
        message: '',
      };
    },
  },
};
</script>

<style scoped>
.er-login__top-block {
  color: var(--cs-gray-08);
  background-color: var(--er-gray-secondary);
  margin-bottom: 24px;
}
.er-login__top-block-title {
  margin-bottom: 8px;
}
.er-login__social-buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.er-login__social-buttons >>> .cs-login-button {
  text-transform: capitalize;
}
.header-button,
.header-loader {
  position: absolute;
  right: 20px;
}
.header-loader {
  top: -68px;
}
</style>
