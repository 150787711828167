<template>
  <app-donate-details>
    <div class="cs-textstyle-item-heading er-donate-details__title">
      Subscribe to a monthly recurring donation
    </div>
    <div class="cs-textstyle-paragraph er-donate-details__description">
      <p>
        The donation subscription will start the same day that you activate it,
        and will continue to run automatically on the same day of the month that
        the subscription was started.
      </p>
    </div>
    <app-donate-button title="Subscribe Now" />
  </app-donate-details>
</template>
<script>
import AppDonateDetails from '@/components/donate/Details.vue';
import AppDonateButton from '@/components/donate/DonateButton.vue';
// import AppPhoneLink from '@/components/general/PhoneLink.vue';

export default {
  components: {
    AppDonateDetails,
    AppDonateButton,
    // AppPhoneLink,
  },
};
</script>
