import Vue from 'vue';
import Skeleton from '@/components/global/Skeleton.vue';

Vue.config.productionTip = false;

// Default app starting point
import App from './App.vue';

// Import the router
import router from './router';

// Enable Ionic in iOS style
import Ionic from '@ionic/vue';
import '@ionic/core/css/core.css';
import '@ionic/core/css/ionic.bundle.css';

Vue.use(Ionic, { mode: 'ios' });

// Import Icons
import '@/assets/icons/css/styles.min.css';

// Used to detect when items move into view
import VueObserveVisibility from 'vue-observe-visibility';
Vue.use(VueObserveVisibility);

// Import Vuex
import { store } from '@/store';

// Import Apollo
import { createProvider } from './vue-apollo';

// Import cs-components project
import 'cs-components';
import 'cs-components/dist/csuite.css';

// Import styles
import '@/styles/variables.css';
import '@/styles/global.css';
import '@/styles/mobile-fixes.css';
import '@/styles/dialog.css';
import '@/styles/toast.css';

// Import services that need to run once
import '@/services/theme';
import '@/services/androidback';

// Inject skeleton component globally

Vue.component('skeleton', Skeleton);

// Run the app
new Vue({
  store,
  router,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount('#app');
