<template>
  <div class="er-notification-item" @click="popupNotification">
    <div class="er-notification-item__row">
      <div class="er-notification-item__image-holder">
        <img
          v-if="notification.picture"
          class="er-notification-item__image"
          :src="notification.picture"
        />
      </div>
      <div class="er-notification-item__row-details">
        <div
          class="cs-textstyle-paragraph er-notification-item__row-description"
        >
          <div
            class="cs-textstyle-paragraph-bold er-notification-item__row-name"
          >
            {{ notification.title }}
          </div>
          <div v-if="notification.description" class="cs-textstyle-paragraph">
            {{ notification.description }}
          </div>
        </div>
        <div
          class="cs-textstyle-paragraph-small er-notification-item__row-time"
        >
          {{ timeSinceNotification }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { time_ago } from '@/services/utils';
import $dialog from '@/services/dialog';
import $inappbrowser from '@/services/inappbrowser';

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    timeSinceNotification() {
      if (!this.notification) return;
      return time_ago(this.notification.scheduled_at);
    },
  },
  methods: {
    async popupNotification() {
      const {
        description: message,
        title,
        picture: image,
        link,
      } = this.notification;
      if (link && link.length) {
        const openLink = await $dialog.confirm({
          image,
          title,
          message,
          okayLabel: 'View',
          cancelLabel: 'Close',
        });
        if (openLink) {
          $inappbrowser.openExternal(link);
        }
      } else {
        $dialog.alert({
          image,
          title,
          message,
          okayLabel: 'Close',
        });
      }
    },
  },
};
</script>

<style scoped>
.er-notification-item {
  padding: 10px;
  background-color: var(--er-gray-primary);
  color: var(--cs-gray-08);
  margin-bottom: 16px;
}
.er-notification-item__row {
  display: flex;
  gap: 16px;
}
.er-notification-item__image-holder {
  height: 60px;
  width: 60px;
  background-color: var(--cs-gray-02);
}

.er-notification-item__image {
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.er-notification-item__row-time {
  color: var(--cs-gray-04);
}
.er-notification-item__row-description {
  color: var(--cs-gray-05);
}
.er-notification-item__row-name {
  color: var(--cs-gray-08);
}
.er-notification-item__row-details {
  flex: 1;
}
.gray05-text {
  color: var(--cs-gray-05);
}
</style>
