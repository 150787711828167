<template>
  <app-modal
    v-bind="$attrs"
    :show="show"
    @close="onClose"
    title="Previously Played"
  >
    <app-previously-played-item
      v-for="item in tracks"
      :item="item"
      :key="item.id"
    />
  </app-modal>
</template>
<script>
import AppModal from '@/components/modals/Modal.vue';
import AppPreviouslyPlayedItem from '@/components/general/PreviouslyPlayedItem.vue';

import RadioHistory from '@/graphql/general/RadioHistory.gql';

import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    AppModal,
    AppPreviouslyPlayedItem,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    tracks: {
      query: RadioHistory,
      fetchPolicy: 'network-only',
    },
  },
  computed: {
    ...mapState('auth', {
      loginWindowOpen: 'loginWindowOpen',
    }),
    ...mapGetters('auth', {
      user: 'user',
    }),
  },
  data() {
    return {};
  },
  methods: {
    async onClose() {
      this.$emit('close');
    },
  },
  watch: {
    show() {
      if (this.show !== true) return;
      this.$apollo.queries.tracks.refetch();
    },
  },
};
</script>

<style scoped></style>
