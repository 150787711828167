<template>
  <div class="er-onboarding">
    <ion-page>
      <ion-content>
        <div class="er-onboarding__content">
          <div class="er-onboarding__buttons">
            <cs-button
              class="er-onboarding__button er-onboarding__button-skip"
              fill="clear"
              @click="skip"
            >
              Skip
              <i class="skip"></i>
            </cs-button>
          </div>
          <ion-slides
            ref="slides"
            pager
            :options="slideOpts"
            @ionSlidesDidLoad="slidesLoaded"
            @ionSlideDidChange="updateIndex"
          >
            <ion-slide>
              <onboarding-slide
                :picture="onboarding1Image"
                title="Live Radio & Programs"
                description="Listen to Effect Radio on your app and see the schedule for upcoming shows."
              />
            </ion-slide>
            <ion-slide>
              <onboarding-slide
                :picture="onboarding2Image"
                title="Stay Connected"
                description="See the latest posts and updates from your favorite shows."
              />
            </ion-slide>
            <ion-slide>
              <onboarding-slide
                :picture="onboarding3Image"
                title="Donate and Contribute"
                description="Share prayer requests with the community or give donate to Effect Radio."
              />
            </ion-slide>
          </ion-slides>

          <div class="er-onboarding__buttons">
            <cs-button
              v-if="index > 0"
              class="er-onboarding__button er-onboarding__button-back"
              fill="clear"
              @click="back"
            >
              <i class="cs-icons-back-angle-filled"></i>
              Back
            </cs-button>
            <cs-button
              v-if="index < 3"
              class="er-onboarding__button er-onboarding__button-next"
              fill="clear"
              @click="next"
            >
              Next
              <i class="cs-icons-next-angle-filled"></i>
            </cs-button>
          </div>
        </div>
      </ion-content>
    </ion-page>
  </div>
</template>

<script>
import * as simpleStorage from '@/services/simple-storage';
import OnboardingSlide from '@/components/general/OnboardingSlide.vue';

const onboarding1Image = require('@/assets/images/Onboarding1.svg');
const onboarding2Image = require('@/assets/images/Onboarding2.svg');
const onboarding3Image = require('@/assets/images/Onboarding3.svg');

export default {
  components: {
    OnboardingSlide,
  },
  data() {
    return {
      slideOpts: {
        initialSlide: 0,
        slideShadows: true,
      },
      index: 0,
      onboarding1Image,
      onboarding2Image,
      onboarding3Image,
    };
  },
  mounted() {
    const onBoarded = this.$store.getters['auth/userOnboarded'];
    if (onBoarded) {
      this.$router.push('/tabs/notabs/login');
    }
  },
  methods: {
    async slidesLoaded() {
      const swiper = await this.$refs.slides.getSwiper();
      swiper.update();
    },
    async back() {
      this.$refs.slides.slidePrev();
    },
    async next() {
      if (this.index === 2) {
        this.$store.dispatch('auth/onboardUser');
        this.$router.push('/tabs/notabs/login');
      } else this.$refs.slides.slideNext();
    },
    async updateIndex() {
      this.index = await this.$refs.slides.getActiveIndex();
    },
    async skip() {
      await simpleStorage.setItem('er_onboarded', 1);
      this.$router.push('/tabs/live');
    },
  },
};
</script>

<style scoped>
ion-content {
  --padding-bottom: 0;
}
.er-onboarding__content {
  height: 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding-bottom: 14px;
}
.er-onboarding ion-slides {
  height: calc(100% - env(safe-area-inset-bottom));
  width: 100vw;
}
.er-onboarding__button.cs-button.cs-button--clear {
  position: absolute;
  z-index: 1;
  text-transform: var(--cs-transform-upper);
  /* --cs-button-text-color: var(--cs-white);
  --cs-button-text-color-hover: var(--cs-white); */
}
.er-onboarding__buttons .er-onboarding__button i {
  font-size: 12px;
}
.er-onboarding__button-back {
  bottom: calc(10px + env(safe-area-inset-bottom));
  left: 0;
}
.er-onboarding__button-back i {
  margin-right: 10px;
}
.er-onboarding__button-next {
  bottom: calc(10px + env(safe-area-inset-bottom));
  right: 0;
}
.er-onboarding__button-next i {
  margin-left: 10px;
}
::v-deep .swiper-pagination-bullet {
  background: var(--cs-gray-03);
  opacity: 1;
}
::v-deep .swiper-pagination-bullet-active {
  background: var(--cs-primary-dark);
}

.er-onboarding__button-skip {
  --cs-button-text-color: var(--cs-gray-04) !important;
  right: 0;
  top: env(safe-area-inset-top);
  z-index: 2 !important;
}
.er-onboarding__button-skip img {
  margin-left: 4px;
}
</style>
