import Vue from 'vue';
import Vuex from 'vuex';
import audio from './modules/audio';
import auth from './modules/auth';
import notifications from './modules/notifications';
import nowplaying from './modules/nowplaying';

Vue.use(Vuex);

export let store = new Vuex.Store({
  modules: {
    audio,
    auth,
    notifications,
    nowplaying,
  },
});
