<template>
  <div class="er-notification-list">
    <notification-item
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
    />
  </div>
</template>

<script>
import NotificationItem from './NotificationItem.vue';

export default {
  components: { NotificationItem },
  props: {
    notifications: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style scoped>
.er-notification-list {
  margin-bottom: 24px;
}
.er-notification-list__title {
  margin-bottom: 24px;
  color: var(--cs-gray-04);
  text-align: center;
  margin-top: 24px;
}
.er-notification-list__title:first-of-type {
  margin-top: 0px;
}
</style>
