import $toast from '@/services/toast';

const AUDIO_SRC = 'https://ice6.securenetsystems.net/EFXAAC';

export default {
  namespaced: true,
  state: {
    isPlaying: false,
    isLoading: false,
    audioElement: null,
    eventsRegistered: false,
    mediaControlsCreated: false,
    mediaControlsEventsRegistered: false,
    mediaControlsShow: null,
  },
  getters: {
    isPlaying: (state) => {
      if (!state.audioElement) return false;
      return !state.audioElement.paused;
    },
  },
  mutations: {
    setIsPlaying(state, isPlaying) {
      state.isPlaying = isPlaying;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setAudioElement(state, element) {
      state.audioElement = element;
    },
    setEventsRegistered(state, registered) {
      state.eventsRegistered = registered;
    },
    setMediaControlsCreated(state, val) {
      state.mediaControlsCreated = val;
    },
    setMediaControlsShow(state, show) {
      state.mediaControlsShow = show;
    },
    setMediaControlsEventsRegistered(state, registered) {
      state.mediaControlsEventsRegistered = registered;
    },
  },
  actions: {
    getLiveDetails() {},
    getAudioElement({ commit }) {
      const audio = document.querySelector('#audio-player');
      commit('setAudioElement', audio);
      return audio;
    },
    async play({ state, commit, dispatch }) {
      const audio = await dispatch('getAudioElement');
      if (!audio) return;

      commit('setIsLoading', true);

      if (!state.eventsRegistered) {
        audio.addEventListener('canplay', () => {
          audio.play();
          setTimeout(() => {
            commit('setIsLoading', false);
          }, 50);
        });
        audio.addEventListener('play', () => {
          if (state.isLoading) {
            dispatch('setPlayingState', true);
          } else {
            dispatch('play');
          }
        });
        audio.addEventListener('pause', () => {
          dispatch('setPlayingState', false);
        });
        audio.addEventListener('abort', () => {
          dispatch('stop');
        });
        audio.addEventListener('ended', () => {
          dispatch('stop');
        });
        audio.addEventListener('error', (err) => {
          console.warn('audio error', err);
          dispatch('stop');
          commit('setIsLoading', false);
          $toast.show('Error loading radio stream');
        });
        commit('setEventsRegistered', true);
      }

      audio.setAttribute('type', 'audio/aac');
      audio.setAttribute('src', AUDIO_SRC);
      setTimeout(() => audio.play(), 10);
    },
    setPlayingState({ commit, dispatch }, isPlaying) {
      commit('setIsPlaying', isPlaying);
      dispatch('updateNowPlaying');
    },
    async pause({ dispatch }) {
      const audio = await dispatch('getAudioElement');
      audio && audio.pause();
      dispatch('updateNowPlaying');
    },
    async stop({ dispatch }) {
      const audio = await dispatch('getAudioElement');
      audio && audio.pause();
      setTimeout(() => {
        // Timeout to avoid the pause event reopening the controls
        if (window.MusicControls) window.MusicControls.destroy();
      }, 10);
    },
    toggle({ state, dispatch }) {
      state.isPlaying ? dispatch('pause') : dispatch('play');
    },
    updateNowPlaying({ rootState, state, commit, dispatch }) {
      // Display the now playing information in the on-device music controls
      const { shows } = rootState;
      if (!shows) return;
      const { liveShow } = shows;

      // If no cordova plugin, or no live show, or it is not currently playing (and no controls displayed), then do nothing
      if (!window.MusicControls) return;
      if (!liveShow) return;
      if (!state.isPlaying && !state.mediaControlsCreated) return;

      // If the controls are already created and the show is the same, only update the play button
      if (
        state.mediaControlsCreated &&
        liveShow.id === state.mediaControlsShow.id
      ) {
        window.MusicControls.updateIsPlaying(state.isPlaying);
        return;
      }

      const {
        channel: { title, speaker, picture },
        // time,
      } = liveShow;

      // const elapsedTime = Math.floor(Date.now() - time.valueOf());
      // lengthOfShow() {
      //   if (!this.playingShow || !this.upcomingShow) return;
      //   if (!this.playingShow.time || !this.upcomingShow.time) return;
      //   return this.upcomingShow.time.valueOf() - this.playingShow.time.valueOf();
      // },

      window.MusicControls.create(
        {
          track: title || 'Effect Radio Live', // optional, default : ''
          artist: speaker || 'Effect Radio', // optional, default : ''
          album: 'Effect Radio', // optional, default: ''
          cover: picture || '', // optional, default : nothing
          // cover can be a local path (use fullpath 'file:///storage/emulated/...', or only 'my_image.jpg' if my_image.jpg is in the www folder of your app)
          //			 or a remote url ('http://...', 'https://...', 'ftp://...')
          isPlaying: state.isPlaying || false, // optional, default : true
          dismissable: true, // optional, default : false

          // hide previous/next/close buttons:
          hasPrev: false, // show previous button, optional, default: true
          hasNext: false, // show next button, optional, default: true
          hasClose: true, // show close button, optional, default: false

          // iOS only, optional
          // TODO duration in seconds
          duration: 60, // optional (in seconds), default: 0
          elapsed: 10, // optional (in seconds), default: 0
          hasSkipForward: false, //optional, default: false. true value overrides hasNext.
          hasSkipBackward: false, //optional, default: false. true value overrides hasPrev.
          skipForwardInterval: 15, //optional. default: 0.
          skipBackwardInterval: 15, //optional. default: 0.
          hasScrubbing: false, //optional. default to false. Enable scrubbing from control center progress bar

          // Android only, optional
          // text displayed in the status bar when the notification (and the ticker) are updated
          ticker: `Now playing: ${title || 'Effect Radio Live'}`,
          //All icons default to their built-in android equivalents
          //The supplied drawable name, e.g. 'media_play', is the name of a drawable found under android/res/drawable* folders
          playIcon: 'play',
          pauseIcon: 'pause',
          // prevIcon: 'media_prev',
          // nextIcon: 'media_next',
          closeIcon: 'close',
          notificationIcon: 'notification',
        },
        () => {
          console.log('music controls success');
        },
        (e) => {
          console.log('music controls error', e);
        }
      );
      commit('setMediaControlsCreated', true);
      commit('setMediaControlsShow', liveShow);

      dispatch('registerMediaControlsEvents');
    },
    registerMediaControlsEvents({ state, dispatch, commit }) {
      if (!state.mediaControlsEventsRegistered) {
        window.MusicControls.subscribe((action) => {
          console.log('music control action', action);
          const message = JSON.parse(action).message;
          switch (message) {
            case 'music-controls-next':
              // Do nothing
              break;
            case 'music-controls-previous':
              // Do nothing
              break;
            case 'music-controls-pause':
              dispatch('pause');
              break;
            case 'music-controls-play':
              dispatch('play');
              break;
            case 'music-controls-destroy':
              dispatch('stop');
              break;
            case 'music-controls-toggle-play-pause':
              dispatch('toggle');
              break;

            // Lockscreen seek controls (iOS only)
            case 'music-controls-seek-to':
              // Do nothing
              break;

            // Headset events (Android only)
            // All media button events are listed below
            case 'music-controls-media-button':
              // Do nothing?
              break;
            case 'music-controls-headset-unplugged':
              dispatch('pause');
              break;
            case 'music-controls-headset-plugged':
              // Do nothing for now
              // dispatch('play');
              break;
            default:
              break;
          }
        });
        window.MusicControls.listen();
        commit('setMediaControlsEventsRegistered', true);
      }
    },
  },
};
