<template>
	<a class="er-link" @click="onClick"><slot /></a>
</template>
<script>
import $iab from '@/services/inappbrowser';

export default {
	props: {
		href: {
			type: String,
			default: '',
		},
	},
	methods: {
		onClick() {
			this.href && $iab.openExternal(this.href);
		},
	},
};
</script>
<style scoped>
.er-link {
	text-decoration: underline;
}
</style>
